import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full md:w-3/5 py-0 mb-6 mx-auto" }
const _hoisted_2 = { class: "w-full bg-white py-5 px-5 flex flex-col items-center md:flex-row" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "font-bold text-base h-10 leading-10 text-gray-700" }
const _hoisted_5 = { class: "text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: _normalizeClass([
          'block flex-shrink w-64 wow animate__animated',
          !_ctx.order
            ? 'md:order-2 animate__slideInRight'
            : 'md:order-1 animate__slideInLeft',
        ]),
        src: _ctx.imgurl
      }, null, 10, _hoisted_3),
      _createElementVNode("div", {
        class: _normalizeClass([
          'px-4 flex flex-col justify-center wow animate__animated',
          _ctx.order
            ? 'md:order-2 animate__slideInRight'
            : 'md:order-1 animate__slideInLeft',
        ])
      }, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.msg), 1)
      ], 2)
    ])
  ]))
}