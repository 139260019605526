import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 w-full" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "bigtitle" }
const _hoisted_4 = { class: "bigtitle" }
const _hoisted_5 = { class: "w-full md:w-3/5 py-5 mx-auto" }
const _hoisted_6 = { class: "w-full bg-white p-5 space-y-8" }
const _hoisted_7 = { class: "text-sm" }
const _hoisted_8 = { class: "grid grid-cols-1 md:grid-cols-2 gap-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_jc_card = _resolveComponent("jc-card")!
  const _component_jc_card_img = _resolveComponent("jc-card-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: require('../assets/about/about-bg.png')
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.bigtitle1), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.indexCard, (item, index) => {
      return (_openBlock(), _createBlock(_component_jc_card, {
        key: index,
        imgurl: item.imgurl,
        title: item.title,
        msg: item.msg,
        order: !index % 2
      }, null, 8, ["imgurl", "title", "msg", "order"]))
    }), 128)),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.bigtitle2), 1),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.jzgMsg), 1),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (item, index) => {
            return (_openBlock(), _createBlock(_component_jc_card_img, {
              key: index,
              imgurl: item.imgurl,
              msg: item.msg
            }, null, 8, ["imgurl", "msg"]))
          }), 128))
        ])
      ])
    ])
  ]))
}