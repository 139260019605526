import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-sm text-gray-500 wow animate__aanimated animate__pulse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "w-full wow animate__aanimated animate__flipInX",
      src: _ctx.imgurl
    }, null, 8, _hoisted_2),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.msg), 1)
  ]))
}