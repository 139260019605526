
import { defineComponent } from "vue";
import JcCard from "./AboutCommponent/JcCard.vue";
import JcCardImg from "./AboutCommponent/JcCardImg.vue";
export default defineComponent({
  components: { JcCard, JcCardImg },
  setup() {
    return {
      bigtitle1: "文化和价值观",
      bigtitle2: "我们的价值观",
      jzgMsg:
        "华盛互联科技公司的四个价值观对于我们如何经营管理、招揽人才、考核员工以及决定员工报酬扮演着重要角色，该四个价值观为：",
      indexCard: [
        {
          imgurl: require("../assets/about/a5.png"),
          title: "企业文化",
          msg: "企业之“道”就是企业文化，最高的管理就是把管理融于文化中，让员工在无处不在的文化教化中不觉得被管理，进而增强员工自我约束能力和工作主动性、创造性，增强自己是企业主人的自觉意识和荣誉感，员工忠诚度必然会随之上升。",
        },
        {
          imgurl: require("../assets/about/a5.png"),
          title: "经营理念",
          msg: "华盛互联科技公司始终秉承坚持诚信、大胆创新、把握市场、优质服务、解决用户需求的经营理念。广大员工恪守诚信、尽责、坚韧、团结、创新的行为规范;共同努力,通过将开心虎APP先进的技术与开心虎中央厨房深度融合，致力成为央厨行业领跑者。",
        },
      ],
      cards: [
        {
          msg: "凝聚是一团火，绽放是满天星。团结有爱是我们的选择，是我们的优先级，只有团结友爱，相互信任，互帮互助，才能成为我们成长进步的有力武器和坚强后盾，才能使团队之间形成强大的凝聚力和向心力。",
          imgurl: require("../assets/about/1.png"),
        },
        {
          msg: "激情是成就人生的基石；激情是工作的灵魂；激情是企业的活力之源。公司坚持把“激情成就梦想，行动创造价值”作为员工的人生信念，为梦想插上翅膀。",
          imgurl: require("../assets/about/2.png"),
        },
        {
          msg: "万物之始，大道至简，行稳致远，进而有为。一个企业要想走得长远，必须脚踏实地，稳中求进，务实前行。我们坚信，10年内开心虎平台能够实现服务100万人,让用餐变得更舒心，更安心，更开心。",
          imgurl: require("../assets/about/3.png"),
        },
        {
          msg: "公司采用合伙人经营制，为有抱负的员工提供一个实现梦想的舞台，以宽阔的视角和胸怀，培养员工与企业的深层关系。员工锐意进取，企业也可以做大做强，巩固行业地位，“互惠共赢”是员工与公司共同追求的终极目标。",
          imgurl: require("../assets/about/4.png"),
        },
      ],
    };
  },
});
